import { CSSProperties } from 'react';
import { COPILOT_LAYOUT, COPILOT_COLORS } from 'globalStyles';

const { SPACING } = COPILOT_LAYOUT;
const { WPP } = COPILOT_COLORS;

export const DEMO_SITES_STYLE: { [key: string]: CSSProperties } = {
  popoverStyle: {
    paddingTop: SPACING[8],
  },
  hyperLinkStyles: {
    cursor: 'pointer',
  },
  actionButtonDiv: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: SPACING[8],
  },
  popOverContent: {
    width: '200px',
    padding: '10px',
  },
  textStyles: {
    color: WPP.grey1000,
  },
  moreItems: {
    padding: SPACING[4],
  },
  addLineItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: WPP.blue500,
    fontSize: '14px',
    width: '130px',
    fontWeight: 600,
    padding: SPACING[8],
    cursor: 'pointer',
  },
  lineItemRow: {
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  pixelRowStyle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    columnGap: SPACING[8],
  },
  pixelsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: SPACING[8],
    width: '100%',
  },
  pixelInput: {
    width: '100%',
  },
  pixelHeader: {
    padding: '0 !important',
  },
  pixelDesc: {
    fontSize: '14px',
    fontWeight: 400,
    color: WPP.grey800,
    padding: '16px 0px',
  },
  secondSubHeader: {
    fontSize: '14px',
    paddingTop: SPACING[8],
    fontWeight: 600,
    marginBottom: 0,
  },
  expandableContainer: {
    marginTop: SPACING[16],
    width: '100%',
  },
  addPixelStyle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: 600,
    color: WPP.grey900,
    cursor: 'pointer',
  },
  noMargin: {
    margin: 0,
  },
  containerMargins: {
    marginBottom: SPACING[24],
  },
  homeAndAnalyticsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    gap: SPACING[24],
  },
  homeAndAnalyticsHeader: {
    marginTop: '36px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: SPACING[8],
  },
  addStrategy: {
    display: 'flex',
    alignItems: 'center',
  },
  strategyCard: {
    width: '97%',
    height: 'fit-content',
    margin: '0 0 16px 11px',
  },
  noPadding: {
    padding: 0,
  },
  flexColumnStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  currencyTag: {
    background: WPP.grey300,
    color: WPP.grey800,
    padding: '0px 8px',
    right: '10px',
  },
  errorMsg: {
    color: '#CC4B00',
  },
  paddingLeft20: {
    paddingLeft: SPACING[20],
  },
  paddingBottom8: {
    paddingBottom: SPACING[8],
  },
  strategyLabelStyle: {
    paddingBottom: SPACING[2],
    color: WPP.grey800,
  },
  dimmerStyle: {
    height: '900px',
  },
};

import _ from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DSP } from 'constantsBase';
import { WppDatepicker, WppSelect, WppListItem, WppButton, WppGrid } from 'buildingBlocks';
import { wppBodyContainer } from 'components/PageTemplate/style';
import { searchByNameOrExtId } from 'utils/semanticUISearch';
import { DATE_FORMAT_JS_DATE, WPP_CUSTOM_DATE_FORMAT } from 'utils/dateTime';
import { getOptionsWithDspIcon } from 'components/OptionWithIcon';
import { RootState } from 'reducers';
import { FilterDropdown } from '.';
import { FILTER, TIME_ZONE_OPTIONS } from '../constants';
import { updateFilter, resetFilter } from '../actions';
import { timeZoneDropDown, filtersRow, filtersButtonRow, containerLayout } from '../style';
import { JobProps, TimeZoneValue } from '../types';

const Filters: React.FC<JobProps> = (props) => {
  const { router, selectedTimezone } = props;
  const { jobStatusOptions, jobTypeOptions, memberOptions, memberFetchError, filter } = useSelector((state: RootState) => state.jobs);
  const filterModified = useMemo(() => ({
    ...filter,
    startDate: filter.startDate?.toDate(),
    endDate: filter.endDate?.toDate(),
  }), [filter]);

  const prettyMembers = getOptionsWithDspIcon(memberOptions);

  // Local state for each filter
  const [startDate, setStartDate] = useState<Date>(filterModified.startDate);
  const [endDate, setEndDate] = useState<Date>(filterModified.endDate);
  const [timezone, setTimezone] = useState<TimeZoneValue>(selectedTimezone);
  const [type, setType] = useState<Array<string>>(filterModified.type);
  const [status, setStatus] = useState<Array<string>>(filterModified.status);
  const [member, setMember] = useState<Array<string>>(filterModified.member);
  const dispatch = useDispatch();

  // Sync local state with props.filter if props.filter changes
  useEffect(() => {
    setStartDate(filterModified.startDate);
    setEndDate(filterModified.endDate);
    setType(filterModified.type);
    setStatus(filterModified.status);
    setMember(filterModified.member);
  }, [filterModified]);

  // Handlers to update each filter state
  const handleStartDateChange = (date: Date) => setStartDate(date);
  const handleEndDateChange = (date: Date) => setEndDate(date);
  const handleTimezoneChangeLocal = (value: TimeZoneValue) => setTimezone(value);
  const handleTypeChange = (value: Array<string>) => setType(value);
  const handleStatusChange = (value: Array<string>) => setStatus(value);
  const handleMemberChange = (value: Array<string>) => setMember(value);

  // Apply filters when button is clicked
  const applyFilters = () => {
    const filters = {
      startDate: moment(startDate),
      endDate: moment(endDate).endOf('day'),
      timezone,
      type,
      status,
      member,
    };

    dispatch(updateFilter(filters, router));
  };

  return (
    <WppGrid container fullWidth style={{ ...wppBodyContainer, ...containerLayout }}>
      <WppGrid item all={24} style={filtersRow}>
        <WppDatepicker
          id="startDate"
          name="startDate"
          size="s"
          placeholder={
            startDate ? moment(startDate).format(WPP_CUSTOM_DATE_FORMAT) : 'Select Start Date'
          }
          value={
            startDate ? moment(startDate).format(WPP_CUSTOM_DATE_FORMAT) : ''
          }
          required
          onWppChange={(event) => {
            if (event.detail.date) {
              handleStartDateChange(moment(event.detail.date as Date).toDate());
            }
          }}
          locale={{ dateFormat: DATE_FORMAT_JS_DATE }}
          labelConfig={{ text: 'Start Date' }}
        />
        <WppDatepicker
          id="endDate"
          name="endDate"
          size="s"
          placeholder={
            endDate ? moment(endDate).format(WPP_CUSTOM_DATE_FORMAT) : 'Select End Date'
          }
          value={
            endDate ? moment(endDate).format(WPP_CUSTOM_DATE_FORMAT) : ''
          }
          required
          onWppChange={(event) => {
            if (event.detail.date) {
              handleEndDateChange(moment(event.detail.date as Date).toDate());
            }
          }}
          locale={{ dateFormat: DATE_FORMAT_JS_DATE }}
          labelConfig={{ text: 'End Date' }}
        />
        <WppSelect
          size="s"
          onWppChange={(event) => handleTimezoneChangeLocal(event.detail.value)}
          value={timezone}
          required
          style={timeZoneDropDown}
          labelConfig={{ text: 'Time Zone' }}
        >
          {
            _.map(TIME_ZONE_OPTIONS, (item) => (
              <WppListItem key={item.value} value={item.value}>
                <p slot="label">{item.text}</p>
              </WppListItem>
            ))
          }
        </WppSelect>
      </WppGrid>
      <WppGrid item all={24} style={filtersRow}>
        <FilterDropdown
          // @ts-ignore child class
          search={{ searchType: 'local' }}
          onChange={(selected) => handleTypeChange(selected)}
          filter={FILTER.JOB_TYPE}
          options={jobTypeOptions}
          value={type}
          keyFn={(option) => option.text}
        />
        <FilterDropdown
          // @ts-ignore child class
          search={{ searchType: 'local' }}
          onChange={(selected) => handleStatusChange(selected)}
          filter={FILTER.STATUS}
          options={jobStatusOptions}
          value={status}
          keyFn={(option) => option.text}
        />
        <FilterDropdown
          // @ts-ignore child class
          onChange={(selected) => handleMemberChange(selected)}
          filter={FILTER.MEMBER}
          options={prettyMembers}
          value={member}
          error={memberFetchError}
          keyFn={(mem) => `${DSP.getById(mem.dsp.id).code} - ${mem.displayName || mem.name}`}
          search={{
            searchType: 'local',
            onSearchChange: searchByNameOrExtId(),
          }}
        />
      </WppGrid>
      <WppGrid item all={24} style={filtersButtonRow}>
        <WppButton variant="secondary" size="m" onClick={() => dispatch(resetFilter(router))}>Reset</WppButton>
        <WppButton size="m" onClick={applyFilters}>Apply Filter</WppButton>
      </WppGrid>
    </WppGrid>
  );
};

export default Filters;

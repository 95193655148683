import React, { useRef, useState } from 'react';
import _ from 'lodash';
import qs from 'qs';
import { useSelector } from 'react-redux';
import { AgGrid, AgGridHeader, AgGridReact, Message, Transition, WppGrid, WppPagination } from 'buildingBlocks';
import WppPermissionPageTemplate from 'components/PageTemplate/WppPermissionPageTemplate';
import { GlobalState } from 'reducers';
import { Permission } from 'utils/featureFlags';
import { InputChangeEventDetail, PaginationChangeEventDetail, User, WppInputCustomEvent } from 'utils/types';
import { COPILOT_LAYOUT } from 'globalStyles';
import PageForbidden from 'containers/403';
import { isQAGlobalReadOnly, withQueryString } from 'utils/functionHelpers';
import { isAdmin } from 'containers/User/utils';
import DataLoading from 'components/DataLoading';
import { BRANDS_STARTING_FETCH_LIMIT } from 'containers/Brands/constants';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import { browserUrlUpdate } from 'containers/Brands/utils';
import WppDeleteConfirmationModal from 'components/WppDeleteConfirmationModal';
import { DemoConfiguration } from 'utils/copilotAPI';
import withRouter, { RouterProps } from 'utils/withRouter';
import { DEMOSITES_PAGE_NAME, DEMOSITES_PER_PAGE_OPTIONS } from './constants';
import { demoSitePluralizer, getDemoSites, parseNumberOrDefault } from './utils';
import SitesTableHeader from './components/SitesTableHeader';
import { DemoSite, StatusMessageType } from './types';
import DemoSitesHeader from './components/DemoSitesHeader';

const { SPACING } = COPILOT_LAYOUT;

const DemoSites = (props: RouterProps) => {
  const { router: { navigate, location } } = props;
  const gridRef = useRef<AgGridReact<DemoSite>>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [searchStr, setSearchStr] = useState<string>('');
  const [demoSites, setDemoSites] = useState<Array<DemoSite>>([]);
  const [demoSitesCount, setDemoSitesCount] = useState<number>(0);
  const [fetchLimit, setFetchLimit] = useState<number>(BRANDS_STARTING_FETCH_LIMIT);
  const [demoSitesToDelete, setDemoSitesToDelete] = useState<Record<string, DemoSite>>({});
  const [startingPage, setStartingPage] = useState<number>(1);
  const user = useSelector<GlobalState>((state) => state.login.user) as User;
  const isAdminUser = isAdmin(user);
  const isQAGlobal = isQAGlobalReadOnly(user);
  const isAdminOrQAGlobal = isAdminUser || isQAGlobal;
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [isSingleDeletion, setIsSingleDeletion] = useState<boolean>(false);
  const [editDemoSiteId, setEditDemoSiteId] = useState<number>();

  const [statusMessage, setStatusMessage] = useState<StatusMessageType>(
    location.state ? location.state.statusMessage : '',
  );

  const refreshDemositesData = async () => {
    const { skip } = qs.parse(location.search.replace(/^\?/, ''));
    await getDemoSites(
      fetchLimit,
      _.toNumber(skip ?? 0),
      searchStr,
      setDemoSitesCount,
      setDemoSites,
      setStatusMessage,
    );
  };

  const setDataConfig = (limit: number, skip: number, search: string) => {
    setFetchLimit(limit);
    setSearchStr(search);
    setStartingPage(skip / limit + 1);
  };

  const updatePaginationAndGetMessages = async (limit: number, skip: number, search: string) => {
    setDataConfig(limit, skip, search);
    await getDemoSites(limit, skip, search, setDemoSitesCount, setDemoSites, setStatusMessage);
    setLoading(false);
  };

  useMount(() => {
    const { limit, skip, search } = qs.parse(location.search.replace(/^\?/, ''));
    const parsedLimit = parseNumberOrDefault(limit, BRANDS_STARTING_FETCH_LIMIT, (num) => num > 0);
    const parsedSkip = parseNumberOrDefault(skip, 0, (num) => num >= 0);
    const parsedSearch = _.isNil(search) ? '' : search;
    updatePaginationAndGetMessages(parsedLimit, parsedSkip, parsedSearch as string);
    setLoading(true);
  });

  const onSearchChange = (event: WppInputCustomEvent<InputChangeEventDetail>) => {
    const search = event.target.value;
    setDemoSitesToDelete({});
    browserUrlUpdate(fetchLimit, 0, search, location, navigate);
    updatePaginationAndGetMessages(fetchLimit, 0, search);
  };

  const handleRowsSelect = () => {
    const selectedSitesData: Array<DemoSite> = gridRef && gridRef.current!.api.getSelectedRows();
    const newSitesToDelete = {};
    selectedSitesData?.forEach((site: DemoSite) => {
      newSitesToDelete[site.id] = site;
    });
    setIsSingleDeletion(false);
    setDemoSitesToDelete(newSitesToDelete);
  };

  const onDataRangeChange = ({
    limit,
    start,
  }: {
    limit: number;
    start: number;
  }) => {
    setLoading(true);
    if (gridRef.current) {
      gridRef.current.api.deselectAll();
    }
    setDemoSitesToDelete({});
    browserUrlUpdate(limit, start, searchStr, location, navigate);
    updatePaginationAndGetMessages(limit, start, searchStr);
  };

  const onDelete = async () => {
    setDeleting(true);
    try {
      const demoSiteIds = _.map(demoSitesToDelete, 'id');
      const where = `{ "id": [${demoSiteIds}] }`;
      await DemoConfiguration.bulkDeleteWhere(where);
      await refreshDemositesData();
      setStatusMessage({
        header: 'Demo site Deletion Successful!',
        body: `Deleted ${_.size(demoSitesToDelete)} ${demoSitePluralizer(
          _.size(demoSitesToDelete),
        )}`,
        isFailure: false,
      });
      setDemoSitesToDelete({});
    } catch (error) {
      setStatusMessage({
        header: 'Demo site Deletion failed!',
        body: 'There was a problem in the deletion process.',
        isFailure: true,
      });
    } finally {
      setDeleting(false);
      setDeleteModalOpen(false);
    }
  };

  const onDismiss = () => {
    // clear the location state and status message
    navigate('.', { replace: true });
    setStatusMessage(null);
  };

  const handleSingleSiteDelete = (id: number) => {
    const matchingSite = _.find(demoSites, (site) => site.id === id);
    const newSitesToDelete = { [id]: matchingSite };
    setDemoSitesToDelete(newSitesToDelete);
    setDeleteModalOpen(true);
    setIsSingleDeletion(true);
  };

  const handleDeselectAll = () => gridRef.current!.api.deselectAll();

  const animatedMessage = statusMessage && (
    <Message
      style={{ marginBottom: SPACING[20] }}
      size="small"
      success={!statusMessage.isFailure}
      error={statusMessage.isFailure}
      icon={statusMessage.isFailure ? 'remove' : 'check'}
      header={statusMessage.header}
      content={statusMessage.body}
      onDismiss={onDismiss}
    />
  );

  const showDeletePanel = !_.isEmpty(demoSitesToDelete) && isAdminOrQAGlobal && !isSingleDeletion;

  return (
    <WppPermissionPageTemplate
      user={user}
      permissions={Permission.adminUsers}
      unauthorizedComponent={
        <PageForbidden message="You are not permitted to view or manage Demo sites." />
      }
      name={DEMOSITES_PAGE_NAME}
      customHeader={
        (
          <DemoSitesHeader
            isAdminOrQAGlobal={isAdminOrQAGlobal}
            setStatusMessage={setStatusMessage}
            demoSiteId={editDemoSiteId}
            setEditDemoSiteId={setEditDemoSiteId}
            setEditLoading={setEditLoading}
            editLoading={editLoading}
            refreshDemositesData={refreshDemositesData}
          />
        )
      }
    >
      <DataLoading loading={loading} pageName={DEMOSITES_PAGE_NAME}>
        <WppGrid container fullWidth>
          <WppGrid item all={24}>
            <Transition.Group animation="fade" duration={500}>{animatedMessage}</Transition.Group>
            <AgGridHeader
              handleDeleteRowAction={() => setDeleteModalOpen(!deleteModalOpen)}
              onSearchChange={_.debounce(onSearchChange, 800)}
              searchPlaceholder="Search by Brands"
              searchValue={searchStr}
              withSearch
              withDeletePanel={showDeletePanel}
              selectedRowCount={_.size(demoSitesToDelete)}
              handleDeselectAll={handleDeselectAll}
            />
          </WppGrid>
        </WppGrid>
        <WppGrid container fullWidth>
          <WppGrid item all={24}>
            <AgGrid
              ref={gridRef}
              rowSelection="multiple"
              onRowSelected={handleRowsSelect}
              columnDefs={SitesTableHeader(isAdminOrQAGlobal, isAdminUser, handleSingleSiteDelete, setEditDemoSiteId, setEditLoading)}
              rowMultiSelectWithClick
              rowData={demoSites}
              loading={editLoading}
              suppressRowClickSelection
            />
          </WppGrid>
          <WppGrid item all={24}>
            <WppPagination
              count={demoSitesCount}
              itemsPerPage={DEMOSITES_PER_PAGE_OPTIONS}
              activePageNumber={startingPage}
              selectedItemPerPage={fetchLimit}
              onWppChange={(event: Event) => {
                const { itemsPerPage, page } = (event as CustomEvent<PaginationChangeEventDetail>).detail;
                onDataRangeChange({ limit: itemsPerPage, start: (page - 1) * itemsPerPage });
              }}
            />
            {isAdminOrQAGlobal && (
              <WppDeleteConfirmationModal
                itemsToDelete={demoSitesToDelete}
                onCancel={() => setDeleteModalOpen(false)}
                open={deleteModalOpen}
                onDelete={onDelete}
                deleteItemName="demo sites"
                deleting={deleting}
                itemName="domain"
                modalName="Delete Demo sites"
                disabled={!isAdminUser}
              />
            )}
          </WppGrid>
        </WppGrid>
      </DataLoading>
    </WppPermissionPageTemplate>
  );
};

export default withRouter(withQueryString(DemoSites));

import _ from 'lodash';
import moment from 'moment';
import { ISO_DATE_TIME_FORMAT_UTC, TIME_FORMAT } from 'utils/dateTime';
import { MessageAlgoType, PublishOptions } from './types';

export const getUTCDateTime = (date: string, time: string) => {
  const dateTimeString = `${date} ${time}`;
  const m = moment(dateTimeString, ISO_DATE_TIME_FORMAT_UTC);
  return m.local().utc().toISOString();
};

export const handleDisabledMins = () => _.filter(_.range(0, 60), (m) => m % 15 !== 0);

export const getPublishOption = (createdAt: string, startDate: string) => {
  if (moment(startDate).isSameOrBefore(createdAt)) {
    return PublishOptions.immediately;
  }
  const duration = moment.duration(moment(startDate).valueOf() - moment(createdAt).valueOf());
  const hours = duration.as('hours');
  if (hours === 1) {
    return PublishOptions.oneHour;
  }
  return PublishOptions.custom;
};

export const getAlgoTypeIds = (hasSelectedAll: boolean, algoTypes: Array<MessageAlgoType>) => {
  if (_.isEmpty(algoTypes)) {
    return null;
  }
  if (hasSelectedAll) {
    return _.toString(_.flatMap(algoTypes, 'ids'));
  }
  return _.toString(_.map(algoTypes, 'id'));
};

export const getUpdatedDateTime = (startDate: string, startTime: string) => (
  moment(startDate).set({
    hour: moment(startTime, TIME_FORMAT).hour(),
    minute: moment(startTime, TIME_FORMAT).minute(),
  })
);

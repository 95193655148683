import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { GlobalState } from 'reducers';
import { Form, WppSideModal, WppTypography, WppGrid } from 'buildingBlocks';
import PageLoading from 'components/PageLoading';
import PageLoadError from 'components/PageLoadError';
import { User } from 'utils/types';
import { useMount } from 'utils/hooks/generic/hookWrappers';
import validator from './validate';
import { submit, fetchMessage, clearFormState } from './actions';
import { PAGE_NAME } from './constants';
import MessageForm from './components/MessageForm';
import MessageFooter from './components/MessageFooter';

type LoadingProps = { id: string };

export const Loading = ({ id } : LoadingProps) => (
  <PageLoading pageName={PAGE_NAME}>{`Loading data for message [${id}]`}</PageLoading>
);

type MessagePageProps = {
  showMessageCreator: boolean
  setShowMessageCreator: React.Dispatch<React.SetStateAction<boolean>>
  onSearchChange?: (search: string) => void
  messageId?: number
};

export const MessagePage = (props: MessagePageProps) => {
  const { messageId } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const { login, messageCreator } = useSelector<GlobalState>((state) => state) as any;
  const {
    loadedMessageData,
    formSubmitting,
    messageContextId,
  } = messageCreator;
  const user = _.get(login, 'session.data.user') as User;
  const initialFormValues = { ...loadedMessageData };

  const formMethods = useForm({
    defaultValues: initialFormValues,
    mode: 'onChange',
    resolver: validator,
  });
  const { getValues, reset, control } = formMethods;
  const content = useWatch({ name: 'content', control });

  useMount(() => {
    if (messageId) {
      fetchMessage(messageId, dispatch);
    }
    return () => {
      dispatch(clearFormState());
    };
  });

  useEffect(() => {
    reset(initialFormValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedMessageData]);

  const handleReset = () => {
    reset(initialFormValues);
  };

  const handleModalClose = () => {
    props.setShowMessageCreator(false);
  };

  const handleSubmit = () => {
    const prevAlgoTypes = loadedMessageData.algoTypes;
    const formValues = getValues();
    const msgId = messageId ? messageId.toString() : '';
    const userId = user.id ? user.id.toString() : '';
    submit({
      formValues,
      msgId,
      messageContextId,
      userId,
      prevAlgoTypes,
      navigate,
      dispatch,
      handleModalClose,
    });
    navigate('/messages?reload=true', { replace: true });
  };

  const handleTriggerClick = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <FormProvider {...formMethods}>
      <Form>
        <WppGrid>
          <WppSideModal
            size="l"
            open={props.showMessageCreator}
            onWppSideModalClose={handleModalClose}
            disableOutsideClick
          >
            <WppTypography type="2xl-heading" tag="h3" slot="header">{_.isUndefined(messageId) ? 'Create New' : 'Edit'} {PAGE_NAME}</WppTypography>
            <div slot="body">
              <MessageForm />
            </div>
            <div slot="actions">
              <MessageFooter
                user={user}
                open={modalOpen}
                onCancel={handleTriggerClick}
                content={content}
                onConfirm={handleSubmit}
                loading={formSubmitting}
                handleModalClose={handleModalClose}
                handleReset={handleReset}
              />
            </div>
          </WppSideModal>
        </WppGrid>
      </Form>
    </FormProvider>
  );
};

const PermissionMessagePage = ({
  messageId,
  showMessageCreator,
  setShowMessageCreator,
  onSearchChange,
}: MessagePageProps) => {
  const { loadingMessage, fetchError } = useSelector<GlobalState>((state) => state.messageCreator) as any;

  return (
    <>
      {loadingMessage && (<Loading id={messageId ? messageId.toString() : ''} />)}
      {fetchError && <PageLoadError error={`Error loading message (${messageId})`} />}
      <MessagePage
        showMessageCreator={showMessageCreator}
        messageId={messageId}
        onSearchChange={onSearchChange}
        setShowMessageCreator={setShowMessageCreator}
      />
    </>
  );
};

export default PermissionMessagePage;

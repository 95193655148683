import _ from 'lodash';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';
import { AnyAction } from 'redux';
import { call, put, take, takeLatest, all, fork } from 'redux-saga/effects';
import {
  NOTIFICATIONS_FETCH_EXISTING, DEFAULT_START_DATE, DEFAULT_END_DATE, NOTIFICATIONS_FETCH_EXISTING_COMPLETED,
  NOTIFICATIONS_FETCH_UPDATES, NOTIFICATIONS_NEW_COMPLETED, NOTIFICATIONS_READ_COMPLETED, NOTIFICATIONS_READ_ALL_COMPLETED, PAYLOAD_VERBS,
} from 'components/Notification/constants';
import {
  createSocketSubscription, getActionNotifications, getUnreadNotifications, getUpdateNotifications, notify,
} from 'components/Notification/utils';
import { APP_NAME } from 'constantsBase';
import { checkForAdBlocker } from 'containers/App/actions';
import { Auth, Users, Environment, Notifications, StarredUserStrategy } from 'utils/copilotAPI';
import { User } from 'utils/types';
import { Router } from 'utils/withRouter';
import {
  LOGIN_SUCCEEDED, LOGIN_FAILED, LOGIN_REQUESTED, LOGIN_GET_SESSION_PENDING, LOGIN_GET_SESSION_FAILED, LOGIN_GET_SESSION_SUCCEEDED,
  LOGIN_GET_SESSION_WITHOUT_USER_SUCCEEDED, LAST_LOGIN_UPDATE_SUCCEEDED, LAST_LOGIN_UPDATE_FAILED, FETCH_STARRED_USER_STRATEGY,
} from './constants';
import { fetchStarredUserStrategy, setCurrentEnv } from './actions';

export const getBrowserTimeZone = () => momentTimeZone.tz.guess() || 'UTC';

export function isExistingUserSession(session) {
  const userId = _.get(session, 'data.user.id', false);
  return userId && userId > 0;
}

export function redirectIfLoginPath(routerProps: Router) {
  // if we have successfully authenticated, but we were on /login in browser, need to redirect otherwise get stuck
  const { location, navigate } = routerProps;
  if (location.pathname.endsWith('/login')) {
    navigate('/');
  }
}

export function* updateLastLogin(user, router: Router, session) {
  const lastLogin = moment.utc().toString();
  const userObj = yield call(Users.put, user.id, { lastLogin });
  if (userObj.status === 200) {
    const { features, settings, roles, userRoles, approver } = _.get(session, 'data.user');
    const userRes = userObj.data;
    const updatedUserObj = { ...userRes, features, settings, roles, userRoles, approver };
    yield put({ type: LAST_LOGIN_UPDATE_SUCCEEDED, payload: updatedUserObj });
    redirectIfLoginPath(router);
  } else {
    yield put({ type: LAST_LOGIN_UPDATE_FAILED });
  }
}

function* getCurrentEnv() {
  const response = yield call(Environment.getEnvironment);
  if (response.status === 200) {
    yield put(setCurrentEnv(response.data.data));
  } else {
    console.error(`got ${response.status} from getenv`);
  }
}

export function* watchForUserNotifications(user: User) {
  const socket = yield call(createSocketSubscription, user);

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const event = yield take(socket);
    const newNotificationCreated = _.get(event, 'action.payload.verb') === PAYLOAD_VERBS.CREATED;

    if (event.action === NOTIFICATIONS_FETCH_EXISTING || newNotificationCreated) {
      let notifications = event.payload;
      const notificationFetchRequired = _.startsWith(notifications, 'Your user does not have access to GET') || _.isNil(notifications) || newNotificationCreated;
      if (notificationFetchRequired) {
        const notificationsRes = yield call(Notifications.getNotifications, { userId: user.id, startDate: DEFAULT_START_DATE, endDate: DEFAULT_END_DATE });
        notifications = notificationsRes.data.notifications;
      }

      if (newNotificationCreated) {
        yield call(notify, `You have a new notification from ${APP_NAME}!`);
      }

      const actionNotifications = getActionNotifications(notifications);
      const updateNotifications = getUpdateNotifications(notifications);

      yield put({
        type: newNotificationCreated ? NOTIFICATIONS_NEW_COMPLETED : NOTIFICATIONS_FETCH_EXISTING_COMPLETED,
        payload: {
          notificationsActionsUnreadCount: _.size(getUnreadNotifications(actionNotifications)),
          notificationsUpdatesUnreadCount: _.size(getUnreadNotifications(updateNotifications)),
          notifications,
        },
      });
    }
    if (event.action === NOTIFICATIONS_FETCH_UPDATES && !newNotificationCreated) {
      yield put({
        type: event.payload.readAll ? NOTIFICATIONS_READ_ALL_COMPLETED : NOTIFICATIONS_READ_COMPLETED,
        payload: event.payload,
      });
    }
  }
}

export function* sessionLoginSucceeded(session, user, router: Router) {
  yield put({ type: LOGIN_GET_SESSION_SUCCEEDED, payload: session });
  yield* updateLastLogin(user, router, session);
  yield fork(watchForUserNotifications, user);
  yield put(fetchStarredUserStrategy(user.id));
  yield call(getCurrentEnv);
}

export function* sessionAlreadyLoggedIn(session, router) {
  if (session.data.authenticated === true) {
    let user = session.data.user;
    if (isExistingUserSession(session)) {
      yield* sessionLoginSucceeded(session, user, router);
    } else {
      const email = session.data.profile.login;
      const browserTimeZone = getBrowserTimeZone();
      const updatedSession = yield call(Auth.updatedSession, email, session, browserTimeZone);
      updatedSession.session.data.user = updatedSession.session.user;
      user = updatedSession.session.data.user;
      if (user.id === -1) {
        yield put({ type: LOGIN_GET_SESSION_WITHOUT_USER_SUCCEEDED, payload: session });
      } else {
        yield* sessionLoginSucceeded(updatedSession.session, user, router);
      }
    }
    yield put(checkForAdBlocker());
  } else {
    yield put({ type: LOGIN_GET_SESSION_FAILED });
  }
}

export function* alreadyLoggedIn({ payload: { router, osApi } }: AnyAction) {
  // osApi is only provided when UI is loaded through wpp os
  const wppOsToken = osApi ? yield call(osApi.getAccessToken) : null;
  const session = yield call(Auth.getSession, getBrowserTimeZone(), wppOsToken);
  yield* sessionAlreadyLoggedIn(session, router);
}

export function* fetchStarredUserStrategies({ payload: userId }: AnyAction) {
  try {
    const res = yield call(StarredUserStrategy.getStarredStrategies, { id: userId });
    yield put({ type: FETCH_STARRED_USER_STRATEGY.COMPLETED, payload: res.data });
  } catch (error) {
    yield put({ type: FETCH_STARRED_USER_STRATEGY.FAILED, payload: error });
  }
}

export function* login({ payload: { oktaRes, router } }: AnyAction) {
  try {
    console.log('in login saga');
    const loginRes = yield call(Auth.login, oktaRes, getBrowserTimeZone());
    console.log({ loginRes });
    // we need to call this to get the rest of the session information that isn't provided by login
    if (loginRes.status === 200) {
      yield put({ type: LOGIN_SUCCEEDED, payload: loginRes });
      console.log('calling getSession');
      // @ts-ignore redux-saga
      const session = yield call(Auth.getSession);
      console.log({ session });
      const { profile, user } = session.data;
      if (!user.firstName || !user.lastName) {
        user.firstName = user.firstName || profile.firstName;
        user.lastName = user.lastName || profile.lastName;
        Users.put(user.id.toString(), { firstName: user.firstName, lastName: user.lastName });
      }
      if (!isExistingUserSession(session)) {
        yield put({ type: LOGIN_GET_SESSION_WITHOUT_USER_SUCCEEDED, payload: session });
      } else {
        yield* sessionAlreadyLoggedIn(session, router);
      }
      yield call(getCurrentEnv);
    } else {
      yield put({ type: LOGIN_FAILED, message: `got ${loginRes.status}` });
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error', e);
    yield put({ type: LOGIN_FAILED, message: e.message });
    // trigger page refresh if there was an error logging in
    history.go(0);
  }
}

export function* loginSaga() {
  yield all([
    takeLatest(LOGIN_REQUESTED, login),
    takeLatest(LOGIN_GET_SESSION_PENDING, alreadyLoggedIn),
    takeLatest(FETCH_STARRED_USER_STRATEGY.STARTED, fetchStarredUserStrategies),
  ]);
}

export default loginSaga;

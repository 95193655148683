import { GOAL_TYPES } from 'constantsBase';
import { DemoSiteFormType } from './types';

export const DEMOSITES_STARTING_FETCH_LIMIT = 10;
export const DEMOSITES_PER_PAGE_OPTIONS = [10, 25, 50, 100];
export const DEMO_SITES_PAGE_NAME = 'Demo Sites';
export const BRAND_NOT_FOUND_ERROR = 'Brand is deleted or does not exist.';

export const INITIAL_DEMOSITE_DETAILS_FORM_VALUES = {
  name: '',
  advertisers: [],
};

export const DEMOSITES_PAGE_NAME = 'Demo Sites';

export enum DemoLinkTypes {
  wppOs = 'WPP OS Link',
  copilotDemo = 'Copilot Demo Link',
}

export enum ActionTypes {
  more = 'more',
  hyperLinks = 'hyperLinks',
  copyLinks = 'copySiteLink',
  delete = 'delete',
}

export const LAST_PAGE_COUNT = 3;
export const FIRST_PAGE_COUNT = 1;
export const SECOND_PAGE_COUNT = 2;
export const REQUIRED_STEP_NUMBER = 3;

export const DEMO_SITES_DSP_NAMES = {
  xndr: 'Xandr',
  theTradeDesk: 'The Trade Desk',
  dv360: 'DV360',
};

export const STRATEGY_DEFAULT_PIXELS = {
  xndr: ['APN pixel 1', 'APN pixel 2', 'APN pixel 3'],
  theTradeDesk: ['TTD pixel 1', 'TTD pixel 2', 'TTD pixel 3'],
  dv360: ['DBM pixel 1', 'DBM pixel 2', 'DBM pixel 3'],
};

export const strategyLineItems = {
  kg3rw2m: 'Amazon - Twitch & Streaming',
  nqdus0o: 'Amazon - FreeVee',
  o98b9rv: 'The Trade Desk - Connected TV',
  qctwxv8: 'The Trade Desk - Online Video',
  r2wynic: 'DV360 - YouTube',
  t5w49y4: 'DV360 - Online Video',
  tuzfqvn: 'Xandr - Connected TV',
  wff3xz8: 'Xandr - Video Banner',
};

export const demoGoalTypes = [{ value: GOAL_TYPES.completionRate.value, displayName: GOAL_TYPES.completionRate.strategyWizardAbbreviation }];

export const initStrategy = {
  strategyName: '',
  brand: '',
  member: '',
  advertiser: '',
  goalType: demoGoalTypes[0],
  currency: '',
  attachedFlight: '',
  lineItems: strategyLineItems,
};

export const INITIAL_DEMOSITE_CREATION_VALUES: DemoSiteFormType = {
  domain: '',
  dates: [],
  lineItems: [''],
  pixels: STRATEGY_DEFAULT_PIXELS,
  strategies: [],
};

export const DEMO_SITE_INFO_RIBBON = ['Enter only the subdomain (e.g., example instead of https://example.os.com) without spaces.',
  'The following information will ONLY be used to configure the <b>Startegy Creation</b> workflow.',
  'The following information will ONLY be used to configure the <b>Home</b> and <b>Analytics</b> page.'];

export enum DemoSiteSteps {
  domain = 'Domain',
  strategyCreation = 'Strategy Creation',
  homeAndAnalytics = 'Home & Analytics',
}

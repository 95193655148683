import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { EnvironmentVariables } from 'utils/types';
import { Dimmer, WppSpinner } from 'buildingBlocks';
import PageNotFound from 'containers/404/404';
import DemoSites from './DemoSites';
import { DEMO_SITES_STYLE } from './styles';

const { dimmerStyle } = DEMO_SITES_STYLE;

const DemoSiteTools = () => {
  const env = useSelector<GlobalState>((state) => state.login.env);
  const envNotLoaded = _.isNil(env);
  const isDev = _.isEqual(env, EnvironmentVariables.dev);
  const renderComponent = () => {
    if (envNotLoaded) {
      return (
        <Dimmer active style={dimmerStyle}>
          <WppSpinner size="m" />
        </Dimmer>
      );
    }
    return isDev ? <DemoSites /> : <PageNotFound />;
  };
  return (
    <>{renderComponent()}</>
  );
};
export default DemoSiteTools;

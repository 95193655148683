import _ from 'lodash';
import { required, applyAllValidators } from 'utils/reactHookFormValidators';

type PixelErrorType = 'required' | 'short' | 'duplicate';

type PixelError = {
  type: PixelErrorType
  message: string
} | null;

type PixelErrors = Array<PixelError>;

const validatePixels = (formValues: Record<string, any>, fieldName: string): Record<string, any> => {
  const value = _.get(formValues, fieldName, {});

  const groupErrors = _.reduce(value, (acc, pixels, groupKey) => {
    // Ensure that pixels is an array
    if (!Array.isArray(pixels)) {
      return acc;
    }

    // Validate each pixel in the group
    const pixelErrors: PixelErrors = _.map(pixels, (pixel) => {
      if (_.isNil(pixel) || _.trim(pixel) === '') {
        return { type: 'required', message: 'This pixel cannot be empty' };
      }

      if (pixel.length <= 2) {
        return { type: 'short', message: 'This pixel must have more than 2 characters' };
      }

      return null;
    });

    // Identify duplicates
    const duplicates = _.filter(pixels, (pixel, index, array) => _.indexOf(array, pixel) !== index);

    // Map over pixelErrors to include duplicates
    const finalPixelErrors = _.map(pixelErrors, (error, index) => {
      if (!error && duplicates.includes(pixels[index])) {
        return { type: 'duplicate', message: 'This pixel is a duplicate' };
      }
      return error;
    });

    // Only add to acc if there are errors
    if (_.some(finalPixelErrors)) {
      acc[groupKey] = _.filter(finalPixelErrors); // Filter out null errors
    }

    return acc;
  }, {});

  return _.isEmpty(groupErrors) ? {} : { [fieldName]: groupErrors };
};

const validateLineItems = (formValues: {}, fieldName: string) => {
  const value = _.get(formValues, fieldName);

  // Return required error if value is not a valid array or is empty
  if (!_.isArray(value) || _.isEmpty(value)) {
    return { [fieldName]: { type: 'required', message: 'Required' } };
  }

  // Generate an array of errors for each item
  const lineItemErrors = _.map(value, (item) => {
    if (_.isNil(item) || _.trim(item) === '') {
      return { type: 'required', message: 'This item cannot be empty' };
    }
    if (item.length <= 2) {
      return { type: 'short', message: 'This item must have more than 2 characters' };
    }
    return null; // No error for this item
  });

  // Identify duplicates and add duplicate errors where needed
  const duplicates = _.filter(value, (item, index, array) => _.indexOf(array, item) !== index);
  // @ts-ignore unusedVal issue
  const duplicateIndices = _.flatMap(duplicates, (dup) => _.map(_.filter(value, (val) => val === dup), (unusedVal, idx) => idx));
  // Update errors at duplicate indices
  _.forEach(duplicateIndices, (index) => {
    lineItemErrors[index] = { type: 'duplicate', message: 'This item is a duplicate' };
  });

  // Return errors if any, otherwise return empty object
  return _.some(lineItemErrors) ? { [fieldName]: lineItemErrors } : {};
};

type LineItemError = {
  message: string
};

type StrategyError = {
  strategyName?: { type: string; message: string }
  brand?: { type: string; message: string }
  member?: { type: string; message: string }
  advertiser?: { type: string; message: string }
  currency?: { type: string; message: string }
  attachedFlight?: { type: string; message: string }
  lineItems?: Record<string, LineItemError>;
};

// Validation for strategy fields
const validateStrategies = (formValues, strategiesField) => {
  const strategies = _.get(formValues, strategiesField, []);

  const strategiesErrors = _.map(strategies, (strategy) => {
    const errors: StrategyError = {};

    // Required fields validation
    const requiredFields = [
      { key: 'strategyName', message: 'You must fill in the strategy name.' },
      { key: 'brand', message: 'You must fill in the brand.' },
      { key: 'member', message: 'You must fill in the member' },
      { key: 'advertiser', message: 'You must fill in the advertiser' },
      { key: 'currency', message: 'You must fill in the currency' },
      { key: 'attachedFlight', message: 'You must fill in the attached flight' },
    ];

    _.forEach(requiredFields, ({ key, message }) => {
      if (!_.get(strategy, key)) {
        errors[key] = { type: 'required', message };
      }
    });

    // Line items validation (Now lineItems is an object)
    if (_.isObject(strategy.lineItems)) {
      // Ensure lineItems errors are nested inside lineItems
      const lineItemsErrors: Record<string, any> = {};

      _.forEach(strategy.lineItems, (lineItemValue, lineItemKey) => {
        if (_.isEmpty(lineItemValue)) {
          lineItemsErrors[lineItemKey] = { message: `Line item "${lineItemKey}" cannot be empty.` };
        }

        // Validate if line item key exists in `strategyLineItems`
        if (!(lineItemKey in strategy.lineItems)) {
          lineItemsErrors[lineItemKey] = { message: `Invalid line item: "${lineItemKey}".` };
        }
      });

      if (!_.isEmpty(lineItemsErrors)) {
        errors.lineItems = lineItemsErrors;
      }
    }

    return _.isEmpty(errors) ? {} : errors;
  });

  // Check if all strategies are valid
  const allStrategiesValid = _.every(strategiesErrors, _.isEmpty);

  // Return undefined if all strategies are valid; otherwise, return the errors object
  return allStrategiesValid ? {} : { [strategiesField]: strategiesErrors };
};

const validateDomainName = (formValues: Object, fieldName: string) => {
  const domainValue = _.get(formValues, fieldName);
  const regex = /[`<>^{|}%:/``.”/\s/]/;
  if (_.isEmpty(domainValue)) {
    return { [fieldName]: { type: 'required', message: 'Required' } };
  }
  if (regex.test(domainValue)) {
    return { [fieldName]: { type: 'invalid', message: 'Domain names should not have spaces or invalid characters: `< > ^ { | } % : / ``.' } };
  }
  return {};
};

export default (values) => {
  const validators = {
    domain: [validateDomainName],
    brand: [required],
    member: [required],
    advertiser: [required],
    currency: [required],
    dates: [required],
    insertionOrder1: [required],
    pixels: [validatePixels],
    lineItems: [validateLineItems],
    strategies: [validateStrategies],
  };
  return { values, errors: applyAllValidators(values, validators) };
};

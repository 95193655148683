/* eslint-disable @typescript-eslint/no-shadow */
import React from 'react';
import moment from 'moment';
import { Button, List, WppDatepicker } from 'buildingBlocks';
import { WPP_DATE_PICKER_FORMAT, NUMBER_DATE_FORMAT } from 'utils/dateTime';
import { CustomDatePickerProps, DatePickerEventDetail, WppDatepickerCustomEvent } from 'utils/types';
import { datePickerStyles, datepickerMessagesDiv } from '../style';

const { postOptions } = datePickerStyles;

type PostOptionsProps = {
  postChoices: Array<string>
  postAction: Function
};

export const PostOptions = (props: PostOptionsProps) => (
  <List style={postOptions}>
    {props.postChoices.map((postOption) => (
      <List.Item key={postOption} style={{ padding: '0' }}>
        <Button
          type="button"
          style={{ width: '100%' }}
          onClick={() => props.postAction(postOption)}
          content={postOption}
        />
      </List.Item>
    ))}
  </List>
);

const CustomDatePicker = (props: CustomDatePickerProps) => {
  const {
    placeholder, showPostOptions,
    startDate, minDate, maxDate,
    hasMinDate, hasMaxDate,
    selected, onChange,
  } = props;

  const calcMinDate = (hasMinDate, minDate, showPostOptions, startDate) => {
    if (hasMinDate) {
      return minDate;
    }
    return !showPostOptions ? moment(startDate) : moment();
  };

  const handleChange = (date) => {
    onChange(date);
  };

  const formattedMinDate = calcMinDate(hasMinDate, minDate, showPostOptions, startDate).format(NUMBER_DATE_FORMAT);
  const formattedMaxDate = hasMaxDate ? moment(maxDate).format(NUMBER_DATE_FORMAT) : undefined;

  return (
    <div style={datepickerMessagesDiv}>
      <WppDatepicker
        locale={{
          dateFormat: WPP_DATE_PICKER_FORMAT,
        }}
        placeholder={selected && moment(selected).isValid() ? moment(selected).format(NUMBER_DATE_FORMAT) : placeholder}
        required
        className="messageWppDatpicker"
        value={selected && moment(selected).isValid() ? moment(selected).format(NUMBER_DATE_FORMAT) : ''}
        onWppChange={(event: WppDatepickerCustomEvent<DatePickerEventDetail>) => {
          const date = event.detail.date as Date;
          if (date && moment(date).isValid()) {
            handleChange(date);
          }
        }}
        size="m"
        minDate={formattedMinDate}
        maxDate={formattedMaxDate}
      />
    </div>
  );
};

export default CustomDatePicker;

import _ from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import { APP_DOMAIN, OPERATOR, RESULTS_LIMIT } from 'constantsBase';
import { Currency, DemoConfiguration } from 'utils/copilotAPI';
import { pluralizer } from 'utils/formattingUtils';
import { PossibleMoment, dateTimeFormatter } from 'utils/dateTime';
import { DEMOSITES_STARTING_FETCH_LIMIT, DemoLinkTypes } from './constants';
import { CurrencyT, DemoSite, DemoSiteFormType, StatusMessageType } from './types';

export const getDemoSites = async (
  limit: number = DEMOSITES_STARTING_FETCH_LIMIT,
  skip: number = 0,
  searchStr: string = '',
  setDemoSitesCount: (x: any) => void,
  setDemoSites: (x: any) => void,
  setStatusMessage: Dispatch<SetStateAction<StatusMessageType>>,
) => {
  try {
    const filter = {
      ...(!_.isEmpty(searchStr) && { or: [{ brandNameToDisplay: { [OPERATOR.CONTAINS]: searchStr } }] }),
    };
    const [demoSitesCountRes, demoSitesRes] = await Promise.all([
      DemoConfiguration.count({ where: filter }),
      DemoConfiguration.get({
        where: filter,
        limit,
        skip,
        sort: 'updatedAt desc',
      }),
    ]);
    setDemoSitesCount(demoSitesCountRes.data.count);
    setDemoSites(demoSitesRes.data);
  } catch (error) {
    setStatusMessage({
      header: 'Failed to get Demo Sites. Please try again later.',
      body: error,
      isFailure: true,
    });
  }
};

export const getHyperLinks = (domain: string) => (
  [
    { name: DemoLinkTypes.wppOs, link: `https://${domain}.os.wpp.com` },
    { name: DemoLinkTypes.copilotDemo, link: `https://demo.${APP_DOMAIN}/${domain}` },
  ]
);

export const demoSitePluralizer = pluralizer('Demo site', 'Demo Sites');

export const isValidDomain = async (domainName: string) => {
  try {
    const filter = { domain: domainName };
    const demoSitesRes = await DemoConfiguration.count({ where: filter });
    return demoSitesRes.data.count === 0;
  } catch (error) {
    console.error('Error checking domain validity:', error);
    return false;
  }
};

export const parseNumberOrDefault = (value, defaultValue, conditionFn) => {
  const parsedValue = _.toNumber(value);
  return conditionFn(parsedValue) ? parsedValue : defaultValue;
};

export const getCurrencies = async () => {
  try {
    const curResponse = await Currency.get({ limit: RESULTS_LIMIT });
    const currencies = _.get(curResponse, 'data', []);
    const currList = _.map(currencies, ({ code, id, name }) => ({
      code,
      id,
      name,
    }));
    return currList;
  } catch (error) {
    console.error('Error fetching currencies:', error);
    return [];
  }
};

export const getCurrencyById = async (id) => {
  try {
    const curResponse = await Currency.getById(id);
    const currencies = _.get(curResponse, 'data');
    const currency: CurrencyT = _.pick(currencies, ['id', 'code', 'name']);
    return currency;
  } catch (error) {
    console.error('Error fetching currencies:', error);
    return [];
  }
};

const convertUiConfigToDB = (demoSiteFormData: DemoSiteFormType, userId: number, demoSiteId: number) => {
  // Dummy mappings for strategy and line item IDs to simplify the example.

  const { brand, member, advertiser, currency: { id }, insertionOrder1, strategies, domain, dates, lineItems, pixels } = demoSiteFormData;
  const strategyIds = [121695, 130316, 130621];

  const ioNames = [insertionOrder1];

  if (demoSiteFormData && demoSiteFormData.insertionOrder2) {
    ioNames.push(demoSiteFormData.insertionOrder2);
  }

  // Map available pixels
  const availablePixels = {
    1: _.get(pixels, 'dv360', []),
    2: _.get(pixels, 'theTradeDesk', []),
    3: _.get(pixels, 'xndr', []),
  };

  // Map strategiesListConfig and analyticsConfig
  const strategiesListConfig = {};
  const analyticsConfig = {};
  strategies.forEach((strategy, idx) => {
    const strategyId = strategyIds[idx];
    strategiesListConfig[strategyId] = {
      strategyName: strategy.strategyName,
      brandNameToDisplay: strategy.brand,
      memberName: strategy.member,
      advertiserName: strategy.advertiser,
      currency: strategy.currency.id,
    };

    analyticsConfig[strategyId] = {
      attachedFlight: strategy.attachedFlight,
      lineItemNames: strategy.lineItems,
    };
  });

  let startDate: PossibleMoment = new Date(dates[0]);
  startDate = dateTimeFormatter.isoDateTime(startDate);

  let endDate: PossibleMoment = new Date(dates[1]);
  endDate = dateTimeFormatter.isoDateTime(endDate);

  return {
    domain: domain.toLowerCase(),
    brandId: 1,
    brandNameToDisplay: brand,
    currency: id,
    memberName: member,
    advertiserName: advertiser,
    startDate,
    endDate,
    ioNames,
    lineItemNames: lineItems,
    availablePixels,
    strategiesListConfig,
    analyticsConfig,
    ...(demoSiteId ? { updatedBy: userId } : { createdBy: userId }),
  };
};

export const saveDemoSite = async (formData: DemoSiteFormType, demoSiteId: number, userId: number, setStatusMessage: Dispatch<SetStateAction<StatusMessageType>>) => {
  const convertedObj = convertUiConfigToDB(formData, userId, demoSiteId);
  try {
    const demoSaveResult = demoSiteId
      ? await DemoConfiguration.put(demoSiteId.toString(), convertedObj)
      : await DemoConfiguration.post(convertedObj);
    const statusCode = _.get(demoSaveResult, 'status');
    if (statusCode === 200 || statusCode === 201) {
      setStatusMessage({
        header: 'Success',
        body: demoSiteId ? 'Demo site update is successful.' : 'New Demo site creation is successful.',
        isFailure: false,
      });
    } else {
      setStatusMessage({
        header: 'Failed',
        body: 'Something went wrong. Please try again.',
        isFailure: true,
      });
    }
  } catch (error) {
    setStatusMessage({
      header: 'Failed',
      body: _.toString(error),
      isFailure: true,
    });
  }
};

const convertDemoSiteToEdit = async (demoSite: DemoSite) => {
  const {
    domain,
    brandNameToDisplay,
    memberName,
    advertiserName,
    currency,
    ioNames,
    startDate,
    endDate,
    lineItemNames,
    availablePixels,
    strategiesListConfig,
    analyticsConfig,
  } = demoSite;

  // Extract insertion orders
  const [insertionOrder1, insertionOrder2] = ioNames;

  const pixels = {
    dv360: availablePixels[1] || [],
    theTradeDesk: availablePixels[2] || [],
    xndr: availablePixels[3] || [],
  };

  const strategies = await Promise.all(
    _.map(strategiesListConfig, async (strategyConfig, strategyId) => {
      const analytics = analyticsConfig[strategyId];

      // Fetch currency data and apply defaults if missing
      const strategyCurr = await getCurrencyById(strategyConfig.currency).catch(() => ({}));

      // Ensure required fields are present by merging with default values
      const currencyData = _.pick(
        _.defaults(strategyCurr, { code: '', name: '', id: 0 }),
        ['id', 'code', 'name'],
      );
      return {
        strategyName: strategyConfig.strategyName,
        brand: strategyConfig.brandNameToDisplay,
        member: strategyConfig.memberName,
        advertiser: strategyConfig.advertiserName,
        currency: currencyData,
        attachedFlight: analytics.attachedFlight,
        lineItems: analytics.lineItemNames,
      };
    }),
  );

  const dates = [dateTimeFormatter.numberDate(startDate), dateTimeFormatter.numberDate(endDate)];

  return {
    domain,
    brand: brandNameToDisplay,
    member: memberName,
    advertiser: advertiserName,
    currency: _.pick(currency, ['id', 'code', 'name']),
    insertionOrder1,
    insertionOrder2,
    strategies,
    dates,
    lineItems: lineItemNames,
    pixels,
  };
};

export const getDemoSiteData = async (demoSiteId: number) => {
  const { data } = await DemoConfiguration.get({ id: demoSiteId, populate: 'currency' });
  const dbDemoSiteData = convertDemoSiteToEdit(data[0]);
  return dbDemoSiteData;
};

import _ from 'lodash';
import React, { useEffect } from 'react';
import moment from 'moment';
import { Controller, useFormContext, UseFormReturn } from 'react-hook-form';
import { WppGrid, WppIconInfo, WppTooltip, WppTypography, WppSelect, WppListItem, WppSegmentedControl, WppSegmentedControlItem } from 'buildingBlocks';
import { TOOLTIP_DESCRIPTIONS } from 'containers/StrategyWizard/ConfigurationByStrategyType/BudgetOptimization/constants';
import { ISO_DATE, TIME_FORMAT, TimePeriod } from 'utils/dateTime';
import { validatedFieldWrapper } from 'utils/wrapperComponentsReactHookForms';
import { getUpdatedDateTime } from '../utils';
import DatePicker from './CustomDatePicker';
import '../override.css';
import { messageFormGridText, messageFormStartDate, timeOptionSelect } from '../style';

const DatePickerWrapper = validatedFieldWrapper(DatePicker);

type FormData = {
  startDate: string
  startTime: string
  startPeriod: TimePeriod
};

const generateTimeOptions = (interval: number = 15) => {
  const options = [];
  let currentTime = moment().startOf('day').hours(0).minutes(0);
  const endOfDay = moment().endOf('day').hours(11).minutes(45);

  while (currentTime <= endOfDay) {
    options.push(currentTime.format('hh:mm'));
    currentTime = currentTime.add(interval, 'minutes');
  }
  return options;
};

const StartDateTime = () => {
  const { control, watch, setValue }: UseFormReturn<FormData> = useFormContext();

  const startDate = watch('startDate');
  const startTime = watch('startTime');
  const dateToUse = startDate ? moment(startDate) : moment();

  const timeOptions = generateTimeOptions();

  const handleTimeChange = (newTime: string): void => {
    const updatedDateTime = getUpdatedDateTime(startDate, newTime);
    setValue('startTime', updatedDateTime.format(TIME_FORMAT), { shouldValidate: true });
    setValue('startDate', updatedDateTime.format(ISO_DATE), { shouldValidate: true });
  };

  const handlePeriodChange = (newPeriod: TimePeriod): void => {
    if (startTime) {
      const timeMoment = moment(startTime, TIME_FORMAT);
      if (newPeriod === TimePeriod.PM && timeMoment.hours() < 12) {
        timeMoment.add(12, 'hours');
      } else if (newPeriod === TimePeriod.AM && timeMoment.hours() >= 12) {
        timeMoment.subtract(12, 'hours');
      }
      setValue('startTime', timeMoment.format(TIME_FORMAT), { shouldValidate: true });
    }
  };

  useEffect(() => {
    if (startDate && startTime) {
      const updatedDateTime = getUpdatedDateTime(startDate, startTime);
      setValue('startDate', updatedDateTime.format(ISO_DATE), { shouldValidate: true });
      setValue('startTime', updatedDateTime.format(TIME_FORMAT), { shouldValidate: true });
    }
  }, [startDate, startTime, setValue]);

  return (
    <WppGrid>
      <div style={{ ...messageFormStartDate, ...messageFormGridText }}>
        <WppTypography tag="p" type="s-strong">Publish Start Date & Time in (UTC)</WppTypography>
        <WppTooltip text={_.get(TOOLTIP_DESCRIPTIONS, 'startDateTime')} config={{ placement: 'bottom' }}>
          <WppIconInfo />
        </WppTooltip>
      </div>
      <div style={messageFormStartDate}>
        <WppGrid>
          <Controller
            name="startDate"
            control={control}
            render={(props) => (
              <DatePickerWrapper
                placeholder="Select Start Date"
                startDate={dateToUse}
                minDate={moment()}
                onChange={(date) => {
                  setValue('startDate', date, { shouldValidate: true });
                }}
                selected={dateToUse}
                closeOnSelect
                hasMinDate
                {..._.omit(props, 'formState')}
              />
            )}
          />
        </WppGrid>
        <WppGrid>
          <Controller
            name="startTime"
            control={control}
            render={(props) => (
              <WppSelect
                onWppChange={(event) => handleTimeChange(event.detail.value)}
                value={startTime || ''}
                id="start-time"
                size="m"
                style={timeOptionSelect}
                withSearch={false}
                name="startTime"
                placeholder={startTime ? _.split(_.toString(startTime), ' ')[0] : 'Select Time'}
                {..._.omit(props, 'formState')}
              >
                {_.map(timeOptions, (time) => (
                  <WppListItem key={time} value={time}>
                    <p slot="label">{time}</p>
                  </WppListItem>
                ))}
              </WppSelect>
            )}
          />
        </WppGrid>
        <WppGrid>
          <Controller
            name="startPeriod"
            control={control}
            render={(props) => {
              const period = (!startTime || (moment(startTime, TIME_FORMAT).hours() < 12)) ? TimePeriod.AM : TimePeriod.PM;
              return (
                <WppSegmentedControl
                  value={period}
                  size="m"
                  onWppChange={(event) => handlePeriodChange(event.detail.value as TimePeriod)}
                  {..._.omit(props, 'formState')}
                >
                  <WppSegmentedControlItem value={TimePeriod.AM}>
                    <WppTypography type="s-midi" tag="p">AM</WppTypography>
                  </WppSegmentedControlItem>
                  <WppSegmentedControlItem value={TimePeriod.PM}>
                    <WppTypography type="s-midi" tag="p">PM</WppTypography>
                  </WppSegmentedControlItem>
                </WppSegmentedControl>
              );
            }}
          />
        </WppGrid>
      </div>
    </WppGrid>
  );
};

export default StartDateTime;

import _ from 'lodash';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { WppActionButton, WppButton, WppIconUndo } from 'buildingBlocks';
import { isQAGlobalReadOnly } from 'utils/functionHelpers';
import { User } from 'utils/types';
import { ConfirmationModal } from './ConfirmationModal';
import { TriggerButtonProps } from '../types';
import { confimMesssageModalFooter } from '../style';

export const TriggerButton = ({ onClick, disabled }: TriggerButtonProps) => (
  <WppButton
    variant="primary"
    disabled={disabled}
    size="m"
    type="submit"
    onClick={onClick}
  >
    Confirm
  </WppButton>
);

type MessageFooterProps = {
  user: User
  open: boolean
  onCancel: React.MouseEventHandler<HTMLWppButtonElement>
  content: string
  onConfirm: React.MouseEventHandler<HTMLWppButtonElement>
  loading: boolean
  handleReset: React.MouseEventHandler<HTMLWppActionButtonElement>
  handleModalClose: React.MouseEventHandler<HTMLWppButtonElement>
};

const MessageFooter = ({
  user,
  open,
  onCancel,
  content,
  onConfirm,
  loading,
  handleReset,
  handleModalClose,
}: MessageFooterProps) => {
  const { getValues, formState: { errors } } = useFormContext();
  const { subject, priority } = getValues();
  const qAReadOnly = isQAGlobalReadOnly(user);

  const disable = qAReadOnly || !_.isEmpty(errors) || !subject || !content || !priority;
  return (
    <div style={confimMesssageModalFooter}>
      <WppActionButton
        variant="primary"
        onClick={handleReset}
      >
        <WppIconUndo slot="icon-start" /> Undo
      </WppActionButton>
      <div style={confimMesssageModalFooter}>
        <WppButton
          variant="secondary"
          size="m"
          onClick={handleModalClose}
        >
          Cancel
        </WppButton>
        <TriggerButton onClick={onCancel} disabled={disable} />
        <ConfirmationModal
          open={open}
          onCancel={onCancel}
          content={content}
          onConfirm={onConfirm}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default MessageFooter;

import _ from 'lodash';
import qs from 'qs';
import React, { Dispatch, useEffect, useState } from 'react';
import { WppGrid, WppTable, WppTableHeader, WppTableHeaderRow, WppTableHeaderCell, WppTableBody, WppCheckbox, WppTypography } from 'buildingBlocks';
import DeleteConfirmationModal from 'components/DeleteConfirmationModal';
import { WppCheckboxCustomEvent } from '@wppopen/components-library/dist/types/components';
import { CheckboxChangeEvent } from '@wppopen/components-library';
import MessageCreator from 'containers/MessageCreator';
import MessageDetails from './MessageDetails';
import { Message, SelectedMessages } from '../types';
import { messagesListingTableColumns } from '../constants';
import { deleteMessages } from '../actions';
import { messagesTableCheckbox } from '../style';

type MessageTableProps = {
  messages: Array<Message>
  selectedMessages: SelectedMessages
  deleteEnabledMessages: SelectedMessages
  modalOpen: boolean
  deleting: boolean
  handleSelectAll: Function
  onMessageSelected: (e: WppCheckboxCustomEvent<CheckboxChangeEvent>, message: Message) => void
  toggleModal: (...args: Array<any>) => void
  dispatch: Dispatch<any>
};

const MessageTable = ({
  messages,
  selectedMessages,
  deleteEnabledMessages,
  modalOpen,
  deleting,
  handleSelectAll,
  onMessageSelected,
  toggleModal,
  dispatch,
}: MessageTableProps) => {
  const [indeterminate, setIndeterminate] = useState<boolean>(false);
  const [selectedMessageId, setSelectedMessageId] = useState<number | null>(null);
  const [showMessageCreator, setShowMessageCreator] = useState<boolean>(false);

  useEffect(() => {
    const isAnyCheckboxChecked = _.some(messages, (message) => _.has(selectedMessages, message.id));
    const isAllCheckboxesChecked = _.size(selectedMessages) === _.size(deleteEnabledMessages);

    setIndeterminate(isAnyCheckboxChecked && !isAllCheckboxesChecked);
  }, [selectedMessages, messages, deleteEnabledMessages]);

  const handleSelectAllChange = (event: WppCheckboxCustomEvent<CheckboxChangeEvent>) => {
    const checked = event.detail.checked;
    handleSelectAll({ checked });
  };

  const checked = !_.isEmpty(selectedMessages) && _.size(selectedMessages) === _.size(deleteEnabledMessages);
  const handleDeleteMessages = () => {
    const { limit, skip, search } = qs.parse(location.search.replace(/^\?/, ''));
    deleteMessages(selectedMessages, limit, skip, search, dispatch);
  };

  const handleMessageClick = (messageId: number) => {
    setSelectedMessageId(messageId);
    setShowMessageCreator(true);
  };

  return (
    <WppGrid item all={24}>
      <WppTable>
        <WppTableHeader>
          <WppTableHeaderRow>
            {messages.length
              ? (
                <WppTableHeaderCell style={messagesTableCheckbox}>
                  <WppCheckbox
                    checked={checked}
                    indeterminate={indeterminate}
                    onWppChange={handleSelectAllChange}
                  />
                </WppTableHeaderCell>
              )
              : null}
            {_.map(messagesListingTableColumns, (column) => (
              <WppTableHeaderCell key={column.value}>
                <WppTypography type="s-strong" tag="p">{column.text}</WppTypography>
              </WppTableHeaderCell>
            ))}
          </WppTableHeaderRow>
        </WppTableHeader>
        <WppTableBody>
          {_.map(messages, (message) => (
            <MessageDetails
              key={message.id}
              message={message}
              onSelected={(e) => onMessageSelected(e, message)}
              selected={_.has(selectedMessages, message.id)}
              onMessageClick={handleMessageClick}
            />
          ))}
        </WppTableBody>
        <DeleteConfirmationModal
          itemsToDelete={selectedMessages}
          onCancel={toggleModal}
          open={modalOpen}
          onDelete={handleDeleteMessages}
          deleting={deleting}
          itemName="subject"
          modalName="Delete Messages"
        />
      </WppTable>
      {showMessageCreator && selectedMessageId && (
        <MessageCreator
          messageId={selectedMessageId}
          showMessageCreator={showMessageCreator}
          setShowMessageCreator={setShowMessageCreator}
        />
      )}
    </WppGrid>
  );
};

export default MessageTable;

import React from 'react';
import { useFormContext } from 'react-hook-form';
import { WppSelect, WppListItem, WppGrid, WppTypography } from 'buildingBlocks';
import { WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components';
import { SelectChangeEventDetail } from '@wppopen/components-library';
import '../override.css';
import { PublishOptions } from '../types';
import { messageFormGridText } from '../style';

type PublishMsgOptionsProps = {
  publishOption: PublishOptions
};

const PublishMsgOptions = ({ publishOption }: PublishMsgOptionsProps) => {
  const { setValue } = useFormContext();

  const handleOnChange = (e: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    setValue('publishOption', e.detail.value);
  };

  return (
    <WppGrid>
      <WppTypography type="s-strong" style={messageFormGridText}>Publish Message</WppTypography>
      <WppSelect
        value={publishOption}
        onWppChange={handleOnChange}
        size="s"
        required
        style={{ width: '100%' }}
      >
        <WppListItem value={PublishOptions.immediately} key={PublishOptions.immediately}>
          <p slot="label">Immediately</p>
        </WppListItem>
        <WppListItem value={PublishOptions.oneHour} key={PublishOptions.oneHour}>
          <p slot="label">In one hour</p>
        </WppListItem>
        <WppListItem value={PublishOptions.custom} key={PublishOptions.custom}>
          <p slot="label">At a specified date and time</p>
        </WppListItem>
      </WppSelect>
    </WppGrid>
  );
};

export default PublishMsgOptions;

import React, { Dispatch, SetStateAction, useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { GlobalState } from 'reducers';
import { WppButton, WppGrid, WppIconPlus, WppTypography } from 'buildingBlocks';
import { BRAND_PAGE_STYLES } from 'containers/Brands/styles';
import { useAsyncEffect } from 'utils/functionHelpers';
import { User } from 'utils/types';
import useSideModalHideFreshdesk from 'utils/hooks/useSideModalHideFreshdesk';
import DemoSiteForm from './DemoSiteForm';
import { DemoSiteFormType, StatusMessageType } from '../types';
import { FIRST_PAGE_COUNT, INITIAL_DEMOSITE_CREATION_VALUES, LAST_PAGE_COUNT } from '../constants';
import validator from '../validate';
import { AddStrategy } from './AddStrategy';
import { getDemoSiteData, saveDemoSite } from '../utils';

const { brandsPageHeaderContainer } = BRAND_PAGE_STYLES;

type DemoSitesHeaderProps = {
  isAdminOrQAGlobal: boolean
  setStatusMessage: Dispatch<SetStateAction<StatusMessageType>>
  demoSiteId: number
  setEditDemoSiteId: Dispatch<SetStateAction<number>>
  setEditLoading: Dispatch<SetStateAction<boolean>>
  editLoading: boolean
  refreshDemositesData: () => Promise<void>
};

const DemoSitesHeader = (props: DemoSitesHeaderProps) => {
  const { isAdminOrQAGlobal, setStatusMessage, demoSiteId, setEditDemoSiteId, setEditLoading, editLoading, refreshDemositesData } = props;
  const [isModalOpen, setModalStatus] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(FIRST_PAGE_COUNT);
  const [isStrategyModalOpen, setStrategyModalStatus] = useState<boolean>(false);
  const [stratIndex, setStratIndex] = useState<number>(null);
  const user = useSelector<GlobalState>((rootState) => rootState.login.user) as User;

  // hide freshdesk button when side modal is open
  useSideModalHideFreshdesk(isModalOpen);

  const formMethods = useForm<DemoSiteFormType>({
    defaultValues: INITIAL_DEMOSITE_CREATION_VALUES,
    mode: 'all',
    resolver: validator,
  });

  const { handleSubmit, reset } = formMethods;

  useAsyncEffect(async () => {
    if (demoSiteId) {
      try {
        const demoData = await getDemoSiteData(demoSiteId);
        reset(demoData);
        setModalStatus(true);
        setEditLoading(false);
      } catch {
        setEditLoading(false);
        setStatusMessage({
          header: 'Failed',
          body: 'Something went wrong. Please try again.',
          isFailure: true,
        });
      }
    }
  }, [demoSiteId]);

  const handleOpenModal = () => setModalStatus(true);
  const handleCloseModal = () => {
    setEditDemoSiteId(null);
    setModalStatus(false);
    reset(INITIAL_DEMOSITE_CREATION_VALUES);
    setCurrentStep(FIRST_PAGE_COUNT);
  };

  const handleStrategyModalOpen = () => setStrategyModalStatus(true);
  const handleStrategyModalClose = () => setStrategyModalStatus(false);

  const formSubmit = async (data: DemoSiteFormType) => {
    const noStrategies = _.isEmpty(_.get(data, 'strategies'));
    if (currentStep === LAST_PAGE_COUNT && !noStrategies) {
      setEditDemoSiteId(null);
      setEditLoading(true);
      const userId = _.get(user, 'id');
      await saveDemoSite(data, demoSiteId, userId, setStatusMessage);
      await refreshDemositesData();
      handleCloseModal();
      setStrategyModalStatus(false);
      setEditLoading(false);
    }
  };

  return (
    <>
      <WppGrid container fullWidth>
        <WppGrid item all={24} style={brandsPageHeaderContainer}>
          <WppTypography tag="h1" type="3xl-heading">
            Demo Sites
          </WppTypography>
          {isAdminOrQAGlobal && (
            <Link to="#">
              <WppButton onClick={handleOpenModal}>
                <WppIconPlus slot="icon-start" /> New Demo Site
              </WppButton>
            </Link>
          )}
        </WppGrid>
      </WppGrid>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(formSubmit)} className="addDemoSiteForm">
          <DemoSiteForm
            isOpen={isModalOpen}
            handleOpenModal={handleOpenModal}
            handleCloseModal={handleCloseModal}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            handleStrategyModalOpen={handleStrategyModalOpen}
            setStratIndex={setStratIndex}
            editedDemoSiteId={demoSiteId}
            editLoading={editLoading}
            isStrategyModalOpen={isStrategyModalOpen}
          />
          {isStrategyModalOpen
            && (
              <AddStrategy
                isOpen={isStrategyModalOpen}
                handleOpenModal={handleStrategyModalOpen}
                handleCloseModal={handleStrategyModalClose}
                selectedStratIndex={stratIndex}
                setSelectedStratIndex={setStratIndex}
              />
            )}
        </form>
      </FormProvider>
    </>
  );
};

export default DemoSitesHeader;
